.budget-list__data{
  flex-basis: 40%;

  @include breakpoint(medium){
    flex-direction: column;
    flex-basis: 16rem;
  }
}

.budget-list__number{
  @include breakpoint(medium){
    display: flex;
    flex-grow: 0;
    padding-bottom: 1.5rem;
    margin-right: 0;
    align-items: center;
    text-align: center;
  }
}
