// Brand
$tuusula-sky: #002ba0;
$tuusula-nature: #418c7c;
$tuusula-sun: #f1da62;
$tuusula-tarmac: #3d476f;
$tuusula-brick: #ff7592;
$tuusula-water: #00bcd2;
$tuusula-coal: #333;

// Variables
$primary: $tuusula-tarmac;
$secondary: $tuusula-water;
$success: $tuusula-nature;
$warning: $tuusula-sun;
$alert: $tuusula-brick;

$proposals: $tuusula-sky;
$actions: $tuusula-nature;
$debates: $tuusula-brick;
$meetings: $tuusula-sun;
//
// $twitter: #55acee;
// $facebook: #3b5998;
// $google: #dd4b39;
