$navbar-color: $primary;
$navbar-color-hover: $tuusula-brick;
$navbar-active-color: $navbar-color-hover;
$offcanvas-navbar-color: rgba(white, .5);
$offcanvas-navbar-color-hover: $white;
$navbar-bg: transparent;
$navbar-bg-hover: transparent;
$navbar-active-bg: transparent;
$navbar-search-border: $dark-gray;
$navbar-search-color: $dark-gray;
$navbar-font-size: 1.5rem;
$tagline-font-size: .75rem;
$tagline-font-size-medium: 1.4rem;
$titlebar-bg: $white;
$titlebar-color: $navbar-color;
$titlebar-link: $navbar-color;
$titlebar-link-active: $navbar-color-hover;
$titlebar-link-spacing: 1rem;

.navbar{
  background-color: $navbar-bg;

  @include breakpoint(medium){
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    font-size: $navbar-font-size;
  }

  @include breakpoint(mediumlarge){
    margin-top: 52px;
  }

  @include breakpoint(large){
    margin-top: 14px;
  }
}

.off-canvas-content{
  .main-nav ul{
    @include breakpoint(medium){
      justify-content: flex-end;

      > li{
        flex: 0 auto;
        margin-left: 2*$titlebar-link-spacing;
      }
    }
  }

  .main-nav__link a{
    display: block;
    padding: 1em 2em;
    color: $navbar-color;

    &:hover{
      background: $navbar-bg-hover;
      color: $navbar-color-hover;
    }

    @include breakpoint(medium){
      padding: 0 0 .75em;
    }

    @include breakpoint(large){
      padding: .75em 0;
    }
  }

  .main-nav__link--active a{
    box-shadow: none;
    color: $navbar-active-color;
    background: $navbar-active-bg;
  }

  .main-nav__link a:hover{
    background: $navbar-bg-hover;
  }
}

.title-bar{
  background: $titlebar-bg;
  color: $titlebar-color;

  a:focus{
    border-top: 0;
    border-bottom: 0;
  }
}

.topbar{
  @include breakpoint(medium){
    align-items: flex-start;
  }
}

.topbar-navigation{
  display: flex;
  flex-direction: column-reverse;
}

.topbar-controls{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .topbar-links{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .topbar-search{
    padding-left: 15px;
  }
}

.topbar__dropmenu > ul > li > a,
.topbar__dropmenu > ul > li > a::after,
.topbar__user__login a{
  @include breakpoint(medium){
    color: $titlebar-link;
  }
}

.topbar__user{
  padding-right: 0;
}

.topbar__user__logged{
  padding-right: 0;

  .dropdown.menu > li.is-dropdown-submenu-parent > a::after{
    @include css-triangle($dropdownmenu-arrow-size, $navbar-color, down);
  }
}

/* Menu icon and off-canvas */
.topbar__menu{
  font-size: 2rem;
}

.topbar__search{
  display: none;

  @include breakpoint(large){
    display: block;
  }

  input,
  input:focus{
    color: $navbar-search-color;
    background: $titlebar-bg;
    border-radius: $input-radius;

    @include breakpoint(medium){
      background: $titlebar-bg;
      border: 1px solid $navbar-search-border;
    }
  }

  button{
    &:hover,
    &:focus{
      background-color: $primary;
      background-color: var(--primary);
    }
  }

  &:not(:focus-within){
    button{
      background-color: $navbar-color;
      color: $button-color;

      @include breakpoint(medium){
        background: $navbar-color;
      }
    }
  }

  &:focus-within{
    input{
      background-color: $titlebar-bg;
      border: 1px solid $navbar-search-border;
      color: $body-font-color;

      &::placeholder{
        color: $dark-medium-gray;
      }
    }
  }

  svg{
    opacity: 1;
  }
}

.logo-wrapper{
  img{
    display: block;
    max-height: 60px;

    @include breakpoint(mediumlarge){
      max-height: 110px;
    }
  }

  .logo-inner{
    display: inline-block;
  }

  .logo-tagline{
    margin-top: 3px;
    text-align: center;
    font-family: $info-font-family;
    font-weight: $global-weight-normal;
    font-size: $tagline-font-size;
    text-transform: uppercase;
    line-height: 1;

    @include breakpoint(mediumlarge){
      font-size: $tagline-font-size-medium;
    }

    a{
      color: $body-font-color;
    }
  }
}

.topbar__notifications .icon,
.topbar__conversations .icon{
  fill: $body-font-color;
}

.topbar__admin__link{
  margin-left: 2*$titlebar-link-spacing;

  a{
    color: $titlebar-link;

    &:hover{
      color: $titlebar-link-active;
    }
  }
}
