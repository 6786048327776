body{
  min-width: 320px;
}

a{
  &:hover,
  &:focus{
    transition: color 300ms ease-in-out 0s;
  }
}
