//  Foundation Settings Overrides
//  -----------------------------
//

// 1. Global
// ---------
$body-font-family: 'Open Sans', sans-serif;
$global-weight-bold: 700;
$info-font-family: Raleway, sans-serif;
$global-radius: 0;

// 4. Base Typography
// ------------------

$header-font-family: BioRhyme, serif;
$header-font-weight: $global-weight-bold;
$keystroke-radius: $global-radius;

// 11. Button
// ----------

$button-radius: $global-radius;

// 14. Card
// --------

$card-border-radius: $global-radius;

// 17. Dropdown
// ------------

$dropdown-radius: $global-radius;

// 20. Forms
// ---------

$input-placeholder-color: $dark-gray;
$select-radius: $global-radius;
$input-radius: $global-radius;
$form-button-radius: $global-radius;

// 21. Label
// ---------

$label-radius: $global-radius;

// 27. Pagination
// --------------

$pagination-radius: $global-radius;

// 28. Progress Bar
// ----------------

$progress-radius: $global-radius;

// 52. Thumbnail
// -------------

$thumbnail-radius: $global-radius;

// 53. Title Bar
// -------------

$titlebar-padding: 1rem .5rem 1.75rem;
