.main-footer,
.mini-footer{
  a{
    color: #fff;
  }
}

.main-footer{
  background-color: $tuusula-tarmac;
}

.mini-footer{
  background-color: $tuusula-tarmac;
}

.main__footer__nav{
  text-align: left;
}

.footer-nav{
  margin: 0;

  li{
    display: block;
    padding: 0;
  }
}

.main__footer__social{
  margin-top: 1rem;
  text-align: center;

  @include breakpoint(medium){
    text-align: left;
  }

  .icon{
    width: 22px;
    height: 22px;
  }
}
